var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-section',{attrs:{"id":"customer-reviews"}},[_c('base-section-heading',{attrs:{"title":"Customer Reviews","icon":"mdi-chat-processing"}}),_c('v-theme-provider',[(_vm.theme == 'light')?_c('v-carousel',{attrs:{"hide-delimiter-background":"","light":""}},_vm._l((_vm.reviews),function(ref,i){
var headshot = ref.headshot;
var name = ref.name;
var title = ref.title;
var text = ref.text;
return _c('v-carousel-item',{key:i},[_c('base-customer-review',{attrs:{"picture":headshot,"client-name":name,"job-title":title,"review":text}})],1)}),1):_vm._e(),(_vm.theme == 'dark')?_c('v-carousel',{attrs:{"hide-delimiter-background":"","dark":""}},_vm._l((_vm.reviews),function(ref,i){
var headshot = ref.headshot;
var name = ref.name;
var title = ref.title;
var text = ref.text;
return _c('v-carousel-item',{key:i},[_c('base-customer-review',{attrs:{"picture":headshot,"client-name":name,"job-title":title,"review":text}})],1)}),1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }